import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import Slider from '@components/Content/Slider/Slider'

import { DealsProps } from '@contracts/deals'

import DealsCard from './DealsCard'

interface DealsData {
	wp: {
		dealsSettings: {
			dealsOptions: DealsProps
		}
	}
}

const DealsSlider = () => {
	const data: DealsData = useStaticQuery(graphql`
		query DealsQuery {
			wp {
				dealsSettings {
					dealsOptions {
						deals {
							name
							specification
							monthlyCost
							deposit
							link
							image {
								localFile {
									childImageSharp {
										gatsbyImageData(width: 450, placeholder: BLURRED)
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const { deals } = data.wp.dealsSettings.dealsOptions

	const slides = deals.map((deal, i) => {
		return <DealsCard deal={deal} key={`deals-${i}`} />
	})

	const duplicatedSlides = [...slides, ...slides]

	return (
		<Slider
			slides={duplicatedSlides}
			icon={<img src="/images/slider-left-primary.svg" alt="" />}
			controlsLocation="middle"
			options={{
				slidesPerView: 1,
				spaceBetween: 24,
				loop: true,
				breakpoints: {
					768: {
						slidesPerView: 2,
					},
					1280: {
						slidesPerView: 3,
					},
				},
			}}
		/>
	)
}

export default DealsSlider
