import React from 'react'

import { PageLinksProps } from '@contracts/content'

import * as styles from './PageLinks.module.scss'
import PageLinksItem from './PageLinksItem'

const PageLinks = (props: PageLinksProps) => {
	return (
		<div className={styles.links}>
			{props.links.map((link) => {
				return <PageLinksItem link={link} key={link.link.id} />
			})}
		</div>
	)
}

export default PageLinks
