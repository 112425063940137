import React from 'react'
import CountUp from 'react-countup'
import ReactVisibilitySensor from 'react-visibility-sensor'

import * as styles from './CountersListItem.module.scss'

export interface CountersListItemProps {
	number: number
	label: string
}

const CountersListItem = (props: CountersListItemProps) => {
	const [endNumber, setEndNumber] = React.useState(0)

	const onReveal = (isVisible: boolean) => {
		if (isVisible) {
			setEndNumber(props.number)
		}
	}

	return (
		<li className={styles.item}>
			<ReactVisibilitySensor delayedCall onChange={onReveal}>
				<CountUp start={0} duration={0.7} end={endNumber} className={styles.number} />
			</ReactVisibilitySensor>

			<span className={styles.label}>{props.label}</span>
		</li>
	)
}

export default CountersListItem
