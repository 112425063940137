import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import USPGridItem from '@components/Content/USP/USPGridItem'
import Wysiwyg from '@components/Layout/Wysiwyg'

import * as styles from './USPGrid.module.scss'

import Choice from '@images/USP/choice.inline.svg'
import Offers from '@images/USP/offers.inline.svg'
import Speed from '@images/USP/speed.inline.svg'

const USPGrid = () => {
	const data = useStaticQuery(graphql`
		query USPSettingsQuery {
			wp {
				uspSettings {
					uspsOptions {
						usps {
							title
							content
						}
						extraText
					}
				}
			}
		}
	`)

	const usps = data.wp.uspSettings.uspsOptions.usps
	const content = data.wp.uspSettings.uspsOptions.extraText

	return (
		<>
			<div className={styles.grid}>
				{usps.map((item, i) => {
					let icon
					switch (i) {
						case 0:
							icon = Offers
							break
						case 1:
							icon = Speed
							break
						case 2:
							icon = Choice
							break
						default:
							icon = Choice
							break
					}
					return <USPGridItem key={`usp-${i}`} item={item} icon={icon} />
				})}
			</div>

			<Wysiwyg content={content} />
		</>
	)
}

export default USPGrid
