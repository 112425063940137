import React, { useCallback, useContext, useEffect } from 'react'

import { LayoutContext } from '../../context/LayoutContext'
import curve from '../../images/parallax-curve.svg'
import * as styles from './Parallax.module.scss'

interface Props {
	background: React.ReactNode
	children: React.ReactNode
	fixedHeader: boolean
	curve: boolean
	stickyImage?: React.ReactNode
}

const Parallax = (props: Props) => {
	const [offsetHeight, setOffsetHeight] = React.useState(0)
	const [headerHeight, setHeaderHeight] = React.useState(0)

	const layoutContext = useContext(LayoutContext)

	useEffect(() => {
		const header = document.querySelector('header')

		if (header) {
			setHeaderHeight(header.clientHeight)
		}

		if (layoutContext.useFixedHeader) {
			layoutContext.useFixedHeader(true)
		}
	}, [])

	const backgroundRef = useCallback(
		(node: HTMLDivElement) => {
			if (node !== null) {
				let offset = node.offsetHeight

				if (props.fixedHeader) {
					offset += headerHeight
				}

				setOffsetHeight(offset)
			}
		},
		[headerHeight]
	)

	const parallaxStyles = [styles.parallax]

	if (props.stickyImage) {
		parallaxStyles.push(styles.hasStickyImage)
	}

	return (
		<div className={parallaxStyles.join(' ')}>
			<div className={styles.background} ref={backgroundRef}>
				{props.background}
				{props.curve && <img src={curve} alt="" className={styles.curve} />}
			</div>
			<div className={styles.content}>
				{props.stickyImage && <div className={styles.stickyImage}>{props.stickyImage}</div>}
				{props.children}
			</div>
		</div>
	)
}

Parallax.defaultProps = {
	fixedHeader: true,
}

export default Parallax
