import React from 'react'

import Wysiwyg from '@components/Layout/Wysiwyg'

import * as styles from './USPGridItem.module.scss'

interface Props {
	item: {
		title: string
		content: string
	}
	icon: any
}

const USPGridItem = (props: Props) => {
	return (
		<div className={styles.item}>
			<div className={styles.icon}>{props.icon()}</div>
			<span className={styles.title}>{props.item.title}</span>
			<Wysiwyg className={styles.content} content={props.item.content} />
		</div>
	)
}

export default USPGridItem
