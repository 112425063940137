import React from 'react'

import Section from '@components/Content/Section/Section'
import DealsSlider from '@components/Deals/DealsSlider'

import * as styles from './DealsSection.module.scss'

import FF from '@images/FF/white-off.svg'

const DealsSection = () => {
	return (
		<Section className={styles.section}>
			<div className={styles.content}>
				<h2 className={styles.title}>Top deals</h2>
				<DealsSlider />
			</div>

			<img src={FF} alt="" className={styles.whiteF} />
		</Section>
	)
}

export default DealsSection
