import React from 'react'

import { CountersProps } from '@contracts/content'

import Section from '../Section/Section'
import CountersList from './CountersList'

const CountersSection = (props: CountersProps) => {
	return (
		<Section>
			<CountersList counters={props.counters} />
		</Section>
	)
}

export default CountersSection
