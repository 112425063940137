import React from 'react'

import { Link } from 'gatsby'

import { PageLinksItemProps } from '@contracts/content'

import caret from '../../../images/caret-right-pink.svg'
import * as styles from './PageLinksItem.module.scss'

interface Props {
	link: PageLinksItemProps
}

const PageLinksItem = (props: Props) => {
	const { link } = props

	return (
		<Link to={link.link.uri} className={styles.item}>
			<div className={styles.content}>
				{link.label ? link.label : link.link.title}
				<img src={caret} alt="" className={styles.icon} />
			</div>
		</Link>
	)
}

export default PageLinksItem
