import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import { BaseReactPlayerProps } from 'react-player/base'
import ReactVisibilitySensor from 'react-visibility-sensor'

import { graphql, useStaticQuery } from 'gatsby'

import Accordion, { AccordionItemProps } from '@components/Accordion/Accordion'
import Button from '@components/Content/Button/Button'
import Section from '@components/Content/Section/Section'
import Video from '@components/Video/Video'

import caret from '../../images/caret-right-pink.svg'
import * as styles from './FAQSection.module.scss'

const FAQSection = () => {
	const data = useStaticQuery(graphql`
		query AllFAQsQuery {
			allWpFaq {
				nodes {
					title
					content
					id
				}
			}
		}
	`)

	const faqs: AccordionItemProps[] = data.allWpFaq.nodes

	return (
		<Section className={styles.section} type="wide">
			<h2 className={styles.title}>FAQs</h2>

			<div className={styles.split}>
				<div>
					<Accordion items={faqs} icon={<img src={caret} alt="" />} />
					<Button uri="/blog" label="More help & advice" large={true} className={styles.button} />
				</div>

				<Video
					m3u8_url="/videos/FF-Animation.m3u8"
					mp4_url="/videos/FF-Animation.mp4"
					className={styles.video}
				/>
			</div>
		</Section>
	)
}

export default FAQSection
