import React from 'react'

import { CountersProps } from '@contracts/content'

import * as styles from './CountersList.module.scss'
import CountersListItem from './CountersListItem'

const CountersList = (props: CountersProps) => {
	return (
		<ul className={styles.list}>
			{props.counters.map((item, i) => {
				return (
					<CountersListItem
						number={item.number}
						label={item.label}
						key={`counter-${i}`}
					/>
				)
			})}
		</ul>
	)
}

export default CountersList
