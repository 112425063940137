// extracted by mini-css-extract-plugin
export var card = "DealsCard-module--card--57846";
export var content = "DealsCard-module--content--630b8";
export var deposit = "DealsCard-module--deposit--3f529";
export var gatsbyImage = "DealsCard-module--gatsby-image--c3e55";
export var image = "DealsCard-module--image--2a6d2";
export var name = "DealsCard-module--name--3e452";
export var price = "DealsCard-module--price--dc85b";
export var prices = "DealsCard-module--prices--3d7bb";
export var spec = "DealsCard-module--spec--f9a75";
export var subContent = "DealsCard-module--sub-content--1f529";