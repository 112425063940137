import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import Button from '@components/Content/Button/Button'

import { DealProps } from '@contracts/deals'

import * as styles from './DealsCard.module.scss'

interface Props {
	deal: DealProps
}

const DealsCard = (props: Props) => {
	const { deal } = props

	return (
		<div className={styles.card}>
			<div className={styles.image}>
				{deal.image.localFile && (
					<GatsbyImage
						image={deal.image.localFile.childImageSharp.gatsbyImageData}
						alt={deal.name}
						className={styles.gatsbyImage}
					/>
				)}
			</div>
			<div className={styles.content}>
				<span className={styles.name}>{deal.name}</span>
				<span className={styles.spec}>
					<small>{deal.specification}</small>
				</span>
				<div className={styles.prices}>
					<span className={styles.price}>{deal.monthlyCost} / month</span>
					<span className={styles.deposit}>
						<small>+ {deal.deposit} deposit</small>
					</span>
				</div>

				<Button uri={deal.link} label="View offer" />
			</div>
		</div>
	)
}

export default DealsCard
