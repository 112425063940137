import React from 'react'

import { OverTheMoonProps } from '@contracts/page'

import * as styles from './OverTheMoon.module.scss'

interface Props {
	data: OverTheMoonProps
}

const OverTheMoon = (props: Props) => {
	return (
		<div className={styles.section}>
			<h2 className={styles.title}>{props.data.title}</h2>
			<div className={styles.cards}>
				{props.data.cards.map((card, index) => (
					<div key={`tagline-${index}`} className={styles.card}>
						<h3 className={styles.tagline}>{card.tagline}</h3>
					</div>
				))}
			</div>
		</div>
	)
}

export default OverTheMoon
